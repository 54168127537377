import React, { useState, useEffect } from "react";
import { fromZonedTime } from 'date-fns-tz';

const WeatherProMaps = ({ currentDate, currentHour, userLang, timezone }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedHour, setSelectedHour] = useState(currentHour);
  const [selectedType, setSelectedType] = useState("ht85"); // Default type
  const [availableModelRun, setAvailableModelRun] = useState("00");
  const [imageSrc, setImageSrc] = useState("");
  const [hourRange, setHourRange] = useState({ min: 0, max: 23 }); // Range for slider
  const [utcHour, setUtcHour] = useState(null);
  const [utcDate, setUtcDate] = useState(null);
  const [availableDate, setAvailableDate] = useState(null);
  const [region, setRegion] = useState("EU/NMB");
  
  const toggleRegion = (newRegion) => {
    setRegion(newRegion);
    setImageSrc(""); // Resetovanje slike prilikom promene regiona
  };
  
  const convertToUTC = (convDate, convHour) => {
    const date = new Date(convDate);
    const dateTimeString = `${date.toISOString().split('T')[0]}T${String(convHour).padStart(2, '0')}:00:00`;
    const utcDateTime = fromZonedTime(dateTimeString, timezone);
    return {
      utcDate: utcDateTime.toISOString().split('T')[0].replace(/-/g, ''),
      utcHour: utcDateTime.getUTCHours(),
    };
  };

  const typeArray = [
    "hpsurf", "hpsurfl", "hpsurfa", "hcloud", "hcloud240", "hlowclo", "hmidclo", "hconv", "hconv35",
    "hcpcp35", "hpcp", "hpcp35", "hpcp104", "hpcp103", "hrasn", "hpcp105", "hpcp106", "hsnowd",
    "adpcp", "adpcp35", "adcpcp35", "adsnow", "freezing", "ht2m", "hv50", "w500", "hv70", "ht85",
    "hvis", "htsoil", "hwsoil", "tt50", "fog", "hhail"
  ];

  const typeLabelArray = [
    "Pressure/Wind speed 10m", "Pressure/Wind speed 100m", "Pressure/Wind", "Total clouds",
    "Clouds cover %", "Low clouds", "Middle clouds", "Convective clouds", "Convective clouds 65%",
    "Convective precipitation (1h acc) 65%", "Total precipitation (1h acc)",
    "Total precipitation (1h acc) 65%conv", "Rain (1h acc)", "Snow (1h acc)", "Rain/Snow (1h acc)",
    "Freezing rain (1h acc)", "Ice pellets (1h acc)", "Snow depth",
    "Total daily precipitation (24h acc)", "Total daily precipitation (24h acc) 65%conv",
    "Convective daily precipitation (24h acc) 65%conv", "Daily snow (24h acc)", "Freezing level",
    "Temperature 2m", "500 hPa temperature", "500 hPa wind", "700 hPa wind", "850 mb temperature",
    "Visibility", "Soil temperature 0-10cm", "Soil wetness 0-10cm", "TT+Sweat+500hPa", "Fog", "Hail chance"
  ];

  

  const getDayName = (baseDate, offset, lang) => {
    const days = {
      en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      rs: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
      hr: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
      el: ['Κυρ', 'Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
      al: ['Die', 'Hën', 'Mar', 'Mër', 'Enj', 'Pre', 'Sht'],
      mk: ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Саб'],
      de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      it: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']
    };

    const year = `20${baseDate.slice(0, 2)}`;
    const month = baseDate.slice(2, 4) - 1; // Meseci su indeksirani od 0
    const day = baseDate.slice(4, 6);

    const date = new Date(year, month, day);
    date.setDate(date.getDate() + offset);

    const dayIndex = date.getDay();
    return days[lang]?.[dayIndex] || days['en'][dayIndex];
  };
  
  const [dayArray, setDayArray] = useState(
    Array.from({ length: 7 }, (_, i) => getDayName(currentDate.replace(/-/g, '').slice(2), i, userLang))
  );

  useEffect(() => {
    if (!availableDate) return;

    // Ažuriraj dayArray kada availableDate postane dostupna
    setDayArray(
      Array.from({ length: 7 }, (_, i) => getDayName(availableDate.slice(0, -2), i, userLang))
    );
  }, [availableDate, userLang]);

  useEffect(() => {
    const determineModelRun = async () => {
      const adjustedDate = currentDate.replace(/-/g, '').slice(2); // Removes dashes and first two characters
      const paths = [
        //`${adjustedDate}12`,
        `${adjustedDate}00`,
        //`${(parseInt(adjustedDate, 10) - 1).toString()}12`
		`${(parseInt(adjustedDate, 10) - 1).toString()}00`
      ];
      for (const path of paths) {
        const res = await fetch(`https://cdn.weather2umbrella.com/w2u_adds/weather_pro/${region}/${path}/fog00.gif`);
        if (res.ok) {
          setAvailableModelRun(path.slice(-2));
		  setAvailableDate(path);
          break;
        }
      }
    };
    determineModelRun();
  }, [currentDate, region]);


  
  useEffect(() => {
    if (!availableDate) return;

    const loadImage = async () => {
      // Definicija akumuliranih tipova
      const accumulatedTypes = ["adcpcp35", "adpcp", "adpcp35", "adsnow"];
      let imageNumber;

      if (accumulatedTypes.includes(selectedType)) {
		const dayIndex = parseInt(selectedDate.slice(-1), 10) || 0;
        imageNumber = (dayIndex + 1).toString(); // Za akumulirane slike koristi samo indeks dana (0-7)
      } else {
		const dayIndex = parseInt(selectedDate.charAt(10), 10) || 0;
        const calculatedNumber = dayIndex * 24 + parseInt(selectedHour, 10);
        imageNumber = calculatedNumber < 100 ? String(calculatedNumber).padStart(2, "0") : String(calculatedNumber); // Za ostale koristi uobičajeni format
      }

      const imagePath = `https://cdn.weather2umbrella.com/w2u_adds/weather_pro/${region}/${availableDate}/${selectedType}${imageNumber}.gif`;

      try {
        const response = await fetch(imagePath, { method: "HEAD" });
        if (response.ok) {
          setImageSrc(imagePath); // Postavljanje URL-a slike
        } else {
          setImageSrc("https://cdn.weather2umbrella.com/w2u_adds/weather_pro/EU/errorimage2.png"); // Postavljanje error slike
        }
      } catch (error) {
        setImageSrc("https://cdn.weather2umbrella.com/w2u_adds/weather_pro/EU/errorimage2.png"); // Postavljanje error slike u slučaju greške
      }
    };

    loadImage();
  }, [availableDate, selectedDate, selectedHour, selectedType, region]);
  

  useEffect(() => {
    const selectedDayIndex = parseInt(selectedDate.slice(-1), 10) || 0; // Ekstraktuj indeks dana
    const min = selectedDayIndex * 24;
    const max = min + 23;
    setHourRange({ min, max });
    setSelectedHour(min);
  }, [selectedDate, userLang]);
  
  useEffect(() => {
    if (availableDate && selectedDate === "") {
      // Postavi `selectedDate` na prvi dan kada `availableDate` postane dostupno
      const baseDate = availableDate.slice(0, -2); // Odseci modelRun iz availableDate
      const initialDay = `${baseDate}0`; // Prvi dan (nulti indeks)
      setSelectedDate(initialDay);
    }
  }, [availableDate, selectedDate]);

  return (
    <div className="weather-pro-maps" style={{ textAlign: "center" }}>
	  
  <div className="flex space-x-3 items-center justify-center">
    <button
      style={{
        background: region === "EU/NMB" ? "rgba(255, 0, 0, 0.3)" : "rgba(0, 0, 255, 0.3)",
      }}
      className="py-1 px-4 rounded-lg"
      onClick={() => toggleRegion("EU/NMB")}
    >
      Europe
    </button>
    <button
      style={{
        background: region === "AM/NM3" ? "rgba(255, 0, 0, 0.3)" : "rgba(0, 0, 255, 0.3)",
      }}
      className="py-1 px-4 rounded-lg"
      onClick={() => toggleRegion("AM/NM3")}
    >
      US & Canada
    </button>
  </div>
	  
	  
      <div className="flex mb-3 justify-center">
        <img src={imageSrc} alt="Weather map" style={{ maxWidth: "100%", maxHeight: "80vh" }} />
      </div>

      

  <div style={{ display: "flex", alignItems: "center", gap: "20px" }} className="flex mt-3 justify-center">
    <div className="text-sm" style={{ flex: "0 0 300px" }}>
      <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)} className="border border-gray-300 rounded-md p-1 w-full">
        {typeArray.map((type, index) => (
          <option key={index} value={type}>
            {typeLabelArray[index]}
          </option>
        ))}
      </select>
    </div>

    <div style={{ flex: "0 0 500px", display: "flex", alignItems: "center", gap: "6px" }} className="text-sm">
      <label>UTC:</label>
      <input
        type="range"
        min={hourRange.min}
        max={hourRange.max}
        step="1"
        value={selectedHour}
        onChange={(e) => setSelectedHour(e.target.value)}
        style={{ width: "100%" }}
      />
      <span>{selectedHour}h</span>
    </div>
  </div>
      
	  <div className="flex space-x-3 items-center justify-center mt-3">
	    {availableDate ? (
	      dayArray.map((day, index) => {
	        const baseDate = availableDate.slice(0, -2); // Odseci modelRun iz availableDate
	        const dayDate = `${baseDate}${index}`;
	        return (
	          <button
	            key={index}
	            style={{
	              background: selectedDate === dayDate ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 255, 0.3)',
	            }}
	            className="py-1 px-4 rounded-lg"
	            onClick={() => setSelectedDate(dayDate)}
	          >
	            {day}
	          </button>
	        );
	      })
	    ) : (
	      <p>Loading days...</p>
	    )}
	  </div>

      
    </div>
  );
};

export default WeatherProMaps;


